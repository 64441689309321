import FaqAccordionSection from "../components/sections/FaqAccordionSection/FaqAccordionSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import React from "react"
import TwoCol_AccountBreakdownSection from "../components/page-specific/Get-Started/TwoCol_AccountBreakdownSection/TwoCol_AccountBreakdownSection"
import GHPricingBreakdown from "../components/page-specific/Corporate-Accounts-Pricing/GHPricingBreakdown/GHPricingBreakdown";
import GHCtaSection from "../components/sections/GHCtaSection/GHCtaSection";
import GHFaqAccordionSection from "../components/sections/GHFaqAccordionSection/GHFaqAccordionSection";
import GHValuePropsCustomBox from "../components/sections/GHValuePropsCustomBox/GHValuePropsCustomBox";
import GHGeneralHeader from "../components/global/GHGeneralHeader";
import GHLayout from "../components/global/GHLayout/GHLayout";

const CorporateAccountsPricing = ( { location } ) => {

    const imageSizesValueProps = {
        maxWidth: "249px",
        maxHeight: "173px"
    }
    const imageSizesMealProps = {
        maxWidth: "78px",
        maxHeight: "78px"
    }


    const valueProps = [
        {
            headline: "Morale boosters",
            subheadline: "Food helps show your team how much you appreciate them.",
            imageSrc: "/images/pages/giftcard-lp/orange_bg.svg",
            imageSize: imageSizesValueProps,
        },
        {
            headline: "Delicious celebrations",
            subheadline: "Free meals are the perfect way to celebrate birthdays, work anniversaries, and more.",
            imageSrc: "/images/pages/giftcard-lp/confetti.svg",
            imageSize: imageSizesValueProps,
        },
        {
            headline: "Flexible solutions",
            subheadline: "Grubhub makes it easy to feed any meeting—in office or virtual.",
            imageSrc: "/images/pages/giftcard-lp/flexible.svg",
            imageSize: imageSizesValueProps,
        },
    ]

    const valuePropsMeals = [
        {
            headline: "Unlimited $0 delivery fees",
            subheadline: "On orders of $12+ from eligible restaurants and stores.",
            imageSrc: "/images/pages/grubhub-plus/5_credit_back_on_pickup.png",
            imageSize: imageSizesMealProps,
        },
        {
            headline: "5% credit back on pickup",
            subheadline: "Earn Grubhub+ Credit for use on future orders.",
            imageSrc: "/images/pages/grubhub-plus/unlimited_0_delivery_fees.png",
            imageSize: imageSizesMealProps,
        },
        {
            headline: "Lower service fees",
            subheadline: "Save even more on eligible orders.",
            imageSrc: "/images/pages/grubhub-plus/lower_services_fees.png",
            imageSize: imageSizesMealProps,
        },
        {
            headline: "Exclusive offers",
            subheadline: "Get tasty deals and more for local restaurants and stores.",
            imageSrc: "/images/pages/grubhub-plus/exclusive_offers.png",
            imageSize: imageSizesMealProps,
        },
    ]

    const cards = [
        {
            title: "Individual Meals",
            description: "Employees can order meals to the office, home or wherever they’re working—you set the budget and control spend.",
            link: "/individual-meals/",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626794994/Grubhub%20Corporate%20Website/2021/Photography%20Elements/1-WaysToOrder-Homepage/EXPORTS-Photography-WaysToOrder-Homepage-IndividualMeals.jpg",
            linkText: "Learn more"
        },
        {
            title: "Group Ordering",
            description: "Send out restaurant choices and let your team order what they want. Or, let employees add food to a shared cart. Any way you order, meals are delivered together and everyone gets something they love.",
            link: "/group-orders/",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-GroupOrdering.jpg",
            linkText: "Learn more"
        }
    ]

    const faqs = [
        {
            header: "What type of occasions should I provide employees with gift cards for?",
            description: `<span>Gift cards or <a href="https://corporate.grubhub.com/individual-meals/" target="_blank">individual meal perks</a> are the perfect gift for birthdays, holidays or “just because,” a free meal through Grubhub complements any occasion or celebration.</span>`
        },
        {
            header: "Can I provide my employees with gift cards virtually?",
            description: `<span>Yes, you can. Employees can receive gift cards virtually via email. Another way to send meal perks to employees virtually is through our individual meals ordering type. Learn more <a href="https://corporate.grubhub.com/individual-meals/" target="_blank">here</a>.</span>`
        },
        {
            header: "Can I bulk order gift cards for employees?",
            description: `<span>Yes, you can. If you need more than $2000 worth of gift cards, bulk ordering is for you.</span>`
        }
    ];

    const leftColBullets = [
        {
            bullets: [
                {
                    text: "Pay only for what your employees spend. No more. No less.",
                },
                {
                    text: "Treat your team to a one-time meal or recurring perks",
                },
                {
                    text: "Set the exact amount employees can spend",
                },
                {
                    text: "Set your own dates, times, and locations",
                },
                {
                    text: "Assign in bulk",
                },
                {
                    text: "Monthly invoicing",
                },
                {
                    text: "24/7 account support",
                },
            ]
        },
    ]
    const rightColBullets = [
        {
            bullets: [
                {
                    text: "Pay only for what your employees spend. No more. No less.",
                    inactive: true,
                    textgrey: true
                },
                {
                    text: "Treat your team to a one-time meal or recurring perks",
                },
                {
                    text: "Pick the exact amount you want to gift",
                },
                {
                    text: "No expiration date",
                },
                {
                    text: "Buy in bulk",
                },
                {
                    text: "Monthly invoicing",
                    inactive: true,
                    textgrey: true
                },
                {
                    text: "24/7 account support",
                    inactive: true,
                    textgrey: true
                }
            ]
        },
    ]

    const pricing = {
        leftColumn: {
            headline: "Employee Meal Credits",
            text: "Provide a meal stipend for employees to treat themselves with delicious meals while working.",
            buttonLabel: "Get started",
            buttonLink: "https://corporate.grubhub.com/get-started/",
            list: leftColBullets

        },
        rightColumn: {
            headline: "Employee Gift Cards",
            text: "Send prepaid physical or digital food gift cards to your team for them to order in the office or at home.",
            buttonLabel: "Order now",
            buttonLink: "https://grubhub-biz.cashstar.com/home/?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=employee-gift-cards-page",
            list: rightColBullets

        },
    };

    return (
        <GHLayout
            location={location}
            title={"Employee Food Gift Cards | Grubhub Corporate"}
            description="Treat employees with food gift cards for celebrations and milestones. Show your team you care by ordering employee gift cards today!"
            utag_data={{
                pageGroup: "grubhub - lead generation",
                subGroup: "two ways to get started",
                pageName: "two ways to get started",
                brand: "grubhub"
            }}
            faqSeoList={faqs}
        >
            <GHGeneralHeader
                headlineMaxWidth={"540px"}
                headline="Treat your team to free food"
                subheadline="Show your appreciation with employee gift cards or individual meal credits."
                subheadlineStyle={{ marginTop: "16px", maxWidth: "358px" }}
                textColor="#242E30"
                background="rgba(16,143,87,0.1)"
                backgroundColorForm='#FFF'
                type="full-image"//"half-image"
                alt="Grubhub delivery driver walking carrying two orange food bags."
                imageSrc="/images/pages/giftcard-lp/giftcard_lp_background_hero.png"
                imageSrcMobile="/images/pages/giftcard-lp/giftcard_lp_background_hero_mobile.png"
                ctaLink={"/get-started"}
                ctaText={"Get started"}
                ctaStyle={{ marginRight: "auto" }}
            />
       <GHValuePropsCustomBox
           headline="Give the gift of tasty food"
           subheadline="Easy ordering. Local favorites. Reliable delivery to your team—no matter where they’re working."
           textColor={"#ffffff"}
           backgroundColor={"#ff8800"}
           valueProps={valueProps}
           layout="vertical"
           theme="catering"
           maxWidth="1024px"
           bottomGraphic={true}
           bottomGraphicNegativeColor={"#F5F3F1"}
           style={{ paddingBottom: "100px" }}
       />
        <GHPricingBreakdown //eslint-disable-line
            backgroundColor={"#F5F3F1"}
            headline={'Place the right order for your team'}
            pricing={pricing}
        />

      <GHCtaSection
          headline="Deliver happiness to your team today"
          subheadline="Get started with Grubhub Corporate Accounts or buy employee gift cards."
          backgroundColor="#C1DADE"
          maxWidth="548px"
          buttonType="secondary"
          ctaText="Order gift cards now"
          to="https://grubhub-biz.cashstar.com/home/?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=employee-gift-cards-page"
          hideButtonTwo={false}
          secondButtonType="secondary"
          secondCtaText="Get started with meal perks"
          secondButtonTo="https://corporate.grubhub.com/get-started/"
          topSpacing={"0px"}
          topSpacingMobile={"0"}
          bottomSpacing={"100px"}
          bottomSpacingMobile={"20px"}
          graphicTopSrc={'/images/pages/giftcard-lp/cta_right_salad.png'}
          graphicTopStyle={{ backgroundSize: "contain", backgroundPosition: "right", backgroundRepeat: "no-repeat", width: "332px", height: "300px" }}
          graphicBottomSrc={'/images/pages/giftcard-lp/cta_left_pizza.png'}
          graphicBottomStyle={{ backgroundSize: "contain", backgroundPosition: "left", backgroundRepeat: "no-repeat", width: "371px", height: "281px" }}
          shrinkGraphicMobile={true}
      />
      <GHFaqAccordionSection faqs={faqs} backgroundColor="white"/>
    </GHLayout>
    )
}

export default CorporateAccountsPricing
